import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import StyledHero from "../components/StyledHero"
import ContactForm from "../components/Contact/Contact"
import SEO from "../components/SEO"

const Contact = ({ data }) => {
  return (
    <Layout>
      <SEO title="Contact" />
      <StyledHero img={data.contactBg.childImageSharp.fluid}/>
      <ContactForm/>
    </Layout>

  )
}

export const query = graphql`
    query {
        contactBg:file(relativePath:{eq:"contactBg.jpg"}) {
            childImageSharp {
                fluid(quality:90, maxWidth:1000) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }
`

export default Contact
