import React from "react"
import Title from "../Title"
import contactStyles from "../../css/contact.module.css"

export default class Contact extends React.Component {

  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  render() {

    return (
      <section className={contactStyles.contact}>
        <Title title="contact" subtitle="form"/>
        <div className={contactStyles.center}>
          <form name="nj-audio-contact" method="POST" className={contactStyles.form} data-netlify-honeypot="bot-field"
                data-netlify="true" onSubmit={this.handleSubmit}>
            <input type="hidden" name="form-name" value="nj-audio-contact"/>
            <div>
              <input type="text" name="name" id="name" className={contactStyles.formControl} placeholder="Name"/>
            </div>
            <div>
              <input type="email" name="email" id="email" className={contactStyles.formControl} placeholder="Email"/>
            </div>
            <div>
              <textarea name="message" id="message" rows="10" className={contactStyles.formControl}
                        placeholder="Message"/>
            </div>
            <div>
              <input type="submit" value="Send" className={contactStyles.submit}/>
            </div>
          </form>
        </div>
      </section>
    )
  }
}


